/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 20px;
  background-color: #f0f2f5;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

/* Open Sidebar Button */
.open-sidebar-btn {
  position: fixed;
  top: 22px;
  right: 3vw;
  padding: 10px 20px;
  background-color: #5d5d5d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1010;
}

/* Sidebar Styles */
.sidebar {
  height: 90vh;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #3d3d3d;
  overflow-x: hidden;
  transition: 0.5s;
  margin-top: 7vh;
  color: white;
  border-radius: 10px;
}

h22 {
  color: white;
  padding-left: 90px;
}

/* Sidebar close button */
.sidebar .closebtn {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: white;
  cursor: pointer;
}

/* Sidebar filter group */
.sidebar .filter-group {
  margin: 20px;
  color: white;
}

.sidebar .filter-group label {
  display: block;
  margin-bottom: 5px;
}

.sidebar .filter-group select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: none;
  background-color: #5d5d5d;
}

/* Apply Filters Button */
.apply-filters-btn {
  margin: 20px;
  padding: 10px 20px;
  background-color: #6d6d6d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000;
}

#compose-table-title {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 10px;
}

/* Container that allows for vertical scrolling */
.table-container {
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 10px 25px;
}

/* Style the table */
table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}

/* Style for table headers */
th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #d3d3d3;
}

/* Background color for table headers */
th {
  background-color: #9d9d9d;
}

/* Alternating row colors */
tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

tbody tr:nth-child(even) {
  background-color: #e9ecef;
}

/* Hover effect for table rows */
tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

/* Style for the PDF viewer container */
.pdf-viewer {
  margin-top: 10px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  overflow: hidden;
}

#pdf-viewer-frame {
  width: 80vw;
  height: 100vh;
  margin: 10px auto;
  border-radius: 10px;
}

/* View More Records Button */
.view-more-container {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.view-more-btn {
  padding: 10px 20px;
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: none;
}

/* Filter Sidebar Styles */
.filter-sidebar {
  position: fixed;
  top: 0;
  right: -250px; /* Position the sidebar off the right side initially */
  width: 250px;
  height: 100%;
  background-color: #3d3d3d;
  transition: right 0.4s ease; /* Change transition property to right */
  overflow-y: auto;
  z-index: 1000;
  padding-top: 10px;
  border-radius: 8px 0 0 8px; /* Rounded left corners */
  box-shadow: -2px 0 15px rgba(0, 0, 0, 0.2); /* Shadow on the left */
}

/* Ensure the sidebar opens when it has the 'open' class */
.filter-sidebar.open {
  right: 0; /* Bring the sidebar into view */
  top: 7vh; /* Adjust top positioning if needed */
}

/* Open Filter Sidebar Button */
.open-filter-sidebar-btn {
  position: fixed;
  top: 20px; /* Adjust the position as needed */
  right: 20px; /* Position it on the right side */
  padding: 10px 20px;
  background-color: #5d5d5d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1015; /* Ensure it's above other elements */
}

.filter-sidebar .closebtn {
  position: absolute;
  top: 10px;
  left: 15px; /* Move the close button to the left */
  font-size: 28px;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
}

.filter-sidebar .filter-content {
  padding: 15px;
  color: white;
}

.filter-sidebar .filter-group {
  margin-bottom: 20px;
}

.filter-sidebar .filter-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: white;
}

.filter-sidebar .filter-group select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #3d3d3d;
}

.filter-sidebar .apply-filters-btn {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000;
}

.filter-sidebar .apply-filters-btn:hover {
  background-color: #0056b3;
}
