/* Global body styling */
body {
    background-color: #f0f2f5; /* Light gray background for a modern look */
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', sans-serif; /* Ensure consistent font */
}

#root {
    height: 100vh;
    width: 100vw;
}

/* Discover page layout */
.discover-page {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

/* Sidebar styling */
.sidebar {
    width: 250px;
    height: 100%;
    box-shadow: 2px 0 15px rgba(0, 0, 0, 0.1); /* Slight shadow for separation */
    transition: width 0.3s ease; /* Smooth transition for any width changes */
    background-color: #004983; /* Sidebar background */
    border-radius: 0 8px 8px 0; /* Rounded corners on the right side */
}

/* Content area styling */
.content-area {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Add padding for consistent spacing */
    background-color: #ffffff; /* White background for the main content area */
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.05); /* Subtle inset shadow for depth */
    border-radius: 8px; /* Slightly rounded corners */
    overflow: hidden; /* Ensure content stays within bounds */
}

/* Iframe styling */
.iframe-content {
    width: 100%;
    height: 100%;
    border: none; /* Remove iframe border for seamless integration */
    border-radius: 8px; /* Match the content area's rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    overflow: hidden; /* Hide any unnecessary scrollbars */
}
