/* General styling */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Title styling */
.title {
    font-size: 3.5rem;
    font-weight: bold;
    margin-top: 20px;
    color: #333;
    text-align: center;
}

/* Progress Bar styling */
.progress-bar {
    width: 30%;
    height: 40px;
    background-color: #e0e0e0;
    border-radius: 15px;
    margin: 15px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}

#progress-indicators {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.progress-indicator {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

/* Colors based on status */
.status-complete {
    background-color: #4CAF50; /* Green for Complete */
}

.status-in-progress {
    background-color: #FFC107; /* Yellow for In Progress */
}

.status-not-started {
    background-color: #b0b0b0; /* Gray for Not Started */
}

.status-past-due {
    background-color: #f28b82; /* Light Red for Past Due */
}

/* Timeline styling */
.timeline-container {
    position: relative;
    width: 90%;
    max-height: 80vh;
    overflow: auto;
}

.timeline-path {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: 1;
}

.year {
    fill: #333;
    font-size: 18px;
    font-weight: bold;
}

/* Milestone styling */
.milestone {
    position: absolute;
    text-align: center;
    width: 150px;
    z-index: 2;
    background: transparent;
}

.icon {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border: 5px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    background-color: #fff;
    z-index: 3;
}

/* Color coding for timeline icons based on status */
.icon.status-complete {
    border-color: #4CAF50;
}

.icon.status-in-progress {
    border-color: #FFC107;
}

.icon.status-not-started {
    border-color: #b0b0b0;
}

.icon.status-past-due {
    border-color: #f28b82;
}

.label {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.description {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}

/* Item list styling */
.item-list {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
}

.item-list li {
    text-align: left;
    padding-left: 5px;
    list-style-type: disc;
    line-height: 1.2;
}

/* Add shadow effect */
.icon, .description {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
