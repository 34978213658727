/* Existing styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header styles */
.header-container {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #004983; /* Updated color */
  color: white;
}

.header-title {
  font-size: 1.5em;
  margin: 0;
  text-align: center;
  flex: 1;
  color: white;
}

.header-buttons {
  display: flex;
  align-items: center;
  z-index: 1003; /* Increased z-index */
}

.toggle-filter-sidebar-btn,
.refresh-button,
.toggle-sidebar-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  margin-left: auto;
  z-index: 1004; /* Increased z-index */
}

.filter-icon-img,
.sidebar-img,
.refresh-img {
  width: 24px;
  height: 24px;
}

.header-buttons img {
  width: 24px;
  height: 24px;
}

.header-buttons .filter-button-container,
.header-buttons .refresh-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.filter-button-container {
  margin-right: 20px;
}

.refresh-button-container {
  margin-right: 20px;
}

/* Main content adjustment when sidebar is open */
.main-content {
  margin-left: 0;
  transition: margin-left 0.4s ease;
}

.main-content.sidebar-open {
  margin-left: 250px;
}

/* Centered title for each page */
.page-title {
  text-align: center;
  margin: 20px 0;
  font-size: 2em;
  color: #333;
}

/* Updated styles for sidebar and stylized S logo */
.toggle-sidebar-btn {
  display: flex;
  align-items: center;
}

.sidebar-img {
  width: 24px;
  height: 24px;
  margin-right: 8px; /* Spacing between the hamburger and S logo */
}

.stylized-s-logo {
  width: 24px;
  height: 24px;
}
