/* Sidebar2 styles */
.sidebar2 {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #004983; /* Background color */
  overflow-x: hidden;
  transition: left 0.4s ease, box-shadow 0.3s ease; /* Smooth transition for sliding and shadow */
  padding-top: 20px;
  border-radius: 0 8px 8px 0; /* Rounded right corners */
  box-shadow: 2px 0 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  z-index: 1000;
}

.sidebar2.open {
  left: 0;
}

.sidebar2 a {
  padding: 12px 20px;
  text-decoration: none;
  font-size: 18px;  
  color: #fff;      
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  border-radius: 4px; /* Slightly rounded corners for links */
  margin: 5px 15px; /* Spacing between links */
}

.sidebar2 a:hover {
  background-color: #147a94; /* Slightly darker shade on hover */
  color: #f1f1f1;
}

.sidebar2 .sidebar2-closebtn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 28px; /* Slightly smaller close button for modern look */
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar2 .sidebar2-closebtn:hover {
  color: #f1f1f1;
}

.sidebar2 .sidebar2-menu-content {
  margin-top: 40px; /* Adjusted margin for better spacing */
}
