/* Import Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

/* Page Layout */
.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
}

.sub-content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
}

/* Headings */
h1, h2 {
    color: #333333;
    margin-top: 10px;
    font-weight: 600;
    text-align: center;
}

/* Top Row 1x4 Grid Layout */
.grid-container-1x4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 1rem;
}

/* Grid Item Styles */
.grid-container-1x4 .grid-item,
.grid-container-3x3 .grid-item {
    background-color: #ffffff;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-container-1x4 .grid-item img {
    max-width: 100%;
    height: 150px;
    object-fit: cover;
}

.grid-container-1x4 .grid-item-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: #333;
    margin-top: 0.5rem;
}

/* Full-Page 3x3 Dashboard Layout */
.grid-container-3x3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

/* Hover Effect for Grid Items */
.grid-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.25);
}

/* Flip Animation Styles */
.flip-container {
    perspective: 1000px;
}

.flip-inner {
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;
}

.flip-front,
.flip-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 12px;
}

/* Modal Styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    transform: scale(0.5);
    transition: transform 0.3s ease, opacity 0.3s ease;
}
.modal-chart {
    min-height: 400px;
}

.modal-overlay.open {
    opacity: 1;
    transform: scale(1);
}

.modal-content {
    background: white;
    width: 80vw;
    height: 80vh;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
}

/* Centralized Resilience Styles */
.centralized-resilience,
.decentralized-resilience,
.key-metrics-analysis,
.key-metrics-bar-chart,
.overall-score-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

#chart {
    width: 100%;
    
}

#overall-score {
    font-size: 16px;
    color: #304758;
    font-weight: bold;
    margin-top: 60px;
}

/* Key Metrics Bar Chart Styles */
.key-metrics-bar-chart {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Tooltip and Data Labels */
.apexcharts-tooltip {
    font-size: 12px;
}

.apexcharts-datalabel {
    font-size: 14px;
    color: #000;
}

/* Placeholder Styling */
.placeholder {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    color: #999;
    border: 2px dashed #ddd;
    border-radius: 12px;
}

/* Wide Box Styling */
.wide-box {
    max-width: 1200px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

/* Button Styles */
.grid-item button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.grid-item button:hover {
    background-color: #388e3c;
}

/* Fade Transition Styles */
.grid-container-3x3.fade-out {
    opacity: 0;
}

.grid-container-3x3.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

/* Exec-Overall Specific Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}
/* Close Button Styles */
.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #f44336; /* Red background */
    color: #ffffff; /* White text */
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1001; /* Ensure it's above other content */
}

.close-button:hover {
    background-color: #d32f2f; /* Darker red on hover */
}
