.root {
  width: auto;
}
.custom-login-wrapper {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  color: #ffffff;
  height: 100vh;
  width: 100vw;
}

.custom-shape-divider-bottom-1710522688 {
  position: absolute;
  bottom: 0rem;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1710522688 svg {
  position: relative;
  display: block;
  width: calc(215% + 1.3px);
  height: 60vh;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1710522688 .shape-fill {
  fill: #004983;
}

#login-img-container {
  position: relative;
}

#login-image {
  z-index: -1;
  height: 80vh;
  position: absolute;
  left: 0vw;
  top: 20vh;
  -webkit-mask-image: linear-gradient(transparent, black 30%);
  mask-image: linear-gradient(transparent, black 27.5%);
  overflow-y: hidden;
}

#login-logo {
  z-index: 10;
  height: 50vh;
  position: absolute;
  left: 10vw;
  bottom: 30vh;
  overflow: hidden;
}

#login-page-title {
  position: relative;
}

#login-page-title > h1 {
  position: absolute;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 8rem;
  color: #253546;
  margin-left: 15vw;
  margin-top: 2.5vh;
  z-index: 10;
  text-shadow: 0px 0px 10px white;
}

#login-form {
  height: 10vh;
  width: 25vw;
  position: relative;
  left: 65vw;
  top: 55vh;

}



.psw {
  color: #494949;
}

/* All components have a class name starting with `amplify` */

.amplify-button {
  font-size: 1rem;
  color: #253546;
  padding: 0.5rem 1.25rem;
  z-index: 100;
  background-color: #494949;
  border: 1px solid black;
}

.amplify-textfield .amplify-field-group__field-wrapper--horizontal {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px 3px;
}

.amplify-field-group .amplify-field-group--horizontal {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 3px 5px;
}

.amplify-field-group__outer-end button {
  border: 0px solid white;
  border-radius: 5px;
  background-color: #b9b9b9;
  margin-left: -2px;
}

.amplify-field-group__outer-end button:hover {
  background-color: #494949;
  color: #ffffff;
  transition: 0.7s;
}

 .amplify-button--fullwidth {
  color: #253546;
  margin-bottom: 0px;
}

.amplify-button--fullwidth:hover {
  background-color: #004983;
  color: #ffffff;
  transition: 0.7s;
}

.amplify-button--small {
  border: 1px solid gray;
  border-radius: 5px;
}

.amplify-button--small:hover {
  background-color: #004983;
  color: #ffffff;
  transition: 0.7s;
}


